var UI = new function () {

    productQuickView = function (itemCode) {
            if ($("#productQuickViewModal").length === 0) {
                return;
            }

            $.get("/products/quickview/" + itemCode + ".aspx")
                .done(function (data) {
                    //Populate and display the modal
                    $("#productQuickViewModal").html(data).modal("show");
                })
                .fail(function () {
                    //Fall back to showing the full product page
                    window.location = "/products/" + itemCode + ".aspx";
                });
        };

    return {
        productQuickView: productQuickView
    };
}();
