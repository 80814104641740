//Start autorun
$(function () {

    if (typeof (WOW) === "function") {
        new WOW().init();
    }

    $(".watermark").each(
        function (i) {
            if ($.trim($(this).val()) === ""
                || $(this).val() === $(this).attr("title")) {
                $(this).val($(this).attr("title")).addClass("watermarkon");
            }
            else { $(this).removeClass("watermarkon"); }
            $(this).focus(function () {
                if ($(this).val() === $(this).attr("title")) { $(this).val("").removeClass("watermarkon"); }
            }).blur(function () {
                if ($.trim($(this).val()) === "") {
                    $(this).val($(this).attr("title")).addClass("watermarkon");
                }
            });
        });

    $('.bandw').hover(function () {
        $(this).toggleClass('bandw');
    });
    //Toggle active state on spec boxes
    $('.content .specbox .pulseanimation').hover(function () {
        $(this).toggleClass('active animated pulse');
    });

    //Toggle active state on spec boxes
    $('.product-animation .specbox').hover(function () {
        $(this).toggleClass('animated pulse active');
    });

    //Toggle scroll for more button on homepage
    $(window).scroll(function () {
        //alert('here');
        $('div#scrollbtn').removeClass('slideInUp');
        $('div#scrollbtn').addClass('slideOutDown');
        return;
    });

    $("#SiteMap li a").hover(function () {
        $(this).stop().animate({
            paddingLeft: "15px"
        }, 300);
    }, function () {
        $(this).stop().animate({
            paddingLeft: "0px"
        }, 300);
    });

    $('input[xtype]').each(function () {
        var ctl = $(this);
        var xtype = ctl.attr('xtype');
        try { ctl.attr('type', xtype); } catch (err) {
            //Do nothing
        }
        ctl.removeAttr('xtype');
    });

    if (Modernizr !== null && !Modernizr.svg) {
        $("img[src$='.svg']").each(function () {
            var fallback = $(this).data("fallback");
            if (fallback === undefined || fallback === null) {
                //Check the parent element
                var parent = $(this).parent();
                fallback = parent.data("fallback");
            }
            if (fallback !== null) {
                //We have something to fall back to
                $(this).attr("src", fallback);
            }
        });
    }

    // Rather than have the partial views run the code on page load (and risk running before the autorun),
    // check for identifiers and run them from here
    if ($("#carousel").length > 0) {
        SetupHeroSlides();
    }
    if ($("#newsletter_signup_submit").length > 0) {
        SetupNewsletterSubmitEvent();
    }
    if ($("#btnWhitepaperRegistrationSubmit").length > 0) {
        SetupWhitePaperRegistrationForm();
    }
    if ($("#btnSGIContactForm").length > 0) {
        SetupSgiForm();
    }
    if ($("#btnNvidiaGridSend").length > 0) {
        SetupNVidiaGridSeedingForm();
    }
    if ($("#btnPartnerInterestSubmit").length > 0) {
        SetupPartnerInterestRegistrationForm();
    }
    if ($("#btnProductInfoRegistrationSubmit").length > 0) {
        SetupProductInformationRequestForm();
    }
    if ($("#btnSendContactForm").length > 0) {
        SetupContactUsForm();
    }
    if ($("#yearFilter").length > 0) {
        SetupMarketingDocumentYearFilterMenu();
    }
});

//End autorun

// Used by _hero.cshtml
function SetupHeroSlides() {
    $("#carousel").carousel({
        interval: 5000
    });
}
// Used by _newsletter-signup.cshtml
function SetupNewsletterSubmitEvent() {
    $('#newsletter_signup_submit').on('click', function (e) {
        e.preventDefault();
        $.ajax({
            cache: false,
            url: "/api/modules/newsletter/signup",
            data: JSON.stringify($('#newsletter_signup_email').val()),
            type: "POST",
            contentType: "application/json; charset=utf-8"
        })
            .done(function () {
                $('#newslettersignup_form').hide();
                $('#newslettersignup_form_success').show();
            })
            .fail(function (xhr) {
                console.log(xhr);
                $('#newslettersignup_form').hide();
                $('#newslettersignup_form_failUnknown').show();
                if (xhr.responseJSON !== undefined && xhr.responseJSON !== null && xhr.responseJSON.Message !== null && xhr.responseJSON.Message !== undefined && xhr.responseJSON.Message.length > 0) {
                    $('#lblErrorMsg').text(xhr.responseJSON.Message);
                }
            });
    });
}

//Used by _whitepaper-registration.cshtml
function SetupWhitePaperRegistrationForm() {
    try {
        $('#btnWhitepaperRegistrationSubmit').on('click', function () {
            $.ajax({
                cache: false,
                url: "/api/contact/whitepaper-registration",
                type: "POST",
                data: JSON.stringify({
                    firstName: $("#tbFirstName").val(),
                    lastName: $("#tbLastName").val(),
                    company: $("#tbCompany").val(),
                    email: $("#tbEmail").val(),
                    reference: $('#tbReference').val(),
                    updates: $("#ddUpdates option:selected").val(),
                    newsletter: $("#cbNewsletter").is(":checked"),
                    Department: 4,
                    Captcha: grecaptcha.getResponse()
                }),
                contentType: "application/json; charset=utf-8"
            }).done(function () {
                var data = $('#whitepaperForm').data('targeturl');
                if (data !== null && data !== undefined && data.length > 1) {
                    siteMessageSuccess("Thank you for registering - please check your inbox for further information. You can download the whitepaper by clicking on the Download Whitepaper button", 10000);
                    $('#whitepaperForm').empty();
                    $('#whitepaperForm').append($('<a />').prop('href', data).prop('target', '_blank').text("Download whitepaper").addClass("btn btn-primary"));
                }
                else {
                    siteMessageSuccess("Thank you for registering - please check your inbox for further information", 10000);
                }
            }).fail(function (xhr) {
                $('#divError').show();
                grecaptcha.reset();
                if (xhr.responseJSON !== undefined && xhr.responseJSON !== null && xhr.responseJSON.Message !== null && xhr.responseJSON.Message !== undefined && xhr.responseJSON.Message.length > 0) {
                    siteMessageError("An issue has occured. " + xhr.responseJSON.Message, 10000);
                }
                else {
                    siteMessageError("An issue has occured.", 10000);
                }
            });
        });
    }
    catch (err) {
        console.log("no whitepaper registration");
        console.log(err);
    }
}

//Used by _sgi-undercut.cshtml
function SetupSgiForm() {
    try {
        $('#btnSGIContactForm').on('click', function () {
            $.ajax({
                cache: false,
                url: "/api/contact/sgiundercut",
                type: "POST",
                data: JSON.stringify({
                    Name: $("#tbSGIName").val(),
                    Email: $("#tbSGIEmail").val(),
                    Phone: $("#tbSGIPhone").val(),
                    Company: $('#tbSGICompany').val(),
                    Message: $('#tbSGIMessage').val(),
                    Captcha: grecaptcha.getResponse()
                }),
                contentType: "application/json; charset=utf-8"
            }).done(function () {
                siteMessageSuccess("One of our HPC experts will contact your shortly to discuss your requirements.", 10000);
            }).fail(function (xhr) {
                grecaptcha.reset();
                if (xhr.responseJSON !== undefined && xhr.responseJSON !== null && xhr.responseJSON.Message !== null && xhr.responseJSON.Message !== undefined && xhr.responseJSON.Message.length > 0) {
                    siteMessageError("An issue has occured. " + xhr.responseJSON.Message, 10000);
                }
                else {
                    siteMessageError("An issue has occured.", 10000);
                }
            });
        });
    }
    catch (err) {
        grecaptcha.reset();
        console.log("no whitepaper registration");
        console.log(err);
    }
}

//Used by _nvidia-grid-seeding-2015.cshtml
function SetupNVidiaGridSeedingForm() {
    try {
        $('#btnNvidiaGridSend').on('click', function () {
            $.ajax({
                cache: false,
                url: "/api/contact/sgiundercut",
                type: "POST",
                data: JSON.stringify({
                    Name: $('#tbNvidiaGridName').val(),
                    JobTitle: $('#tbNvidiaGridJobTitle').val(),
                    Email: $('#tbNvidiaGridEmail').val(),
                    Phone: $('#tbNvidiaGridPhone').val(),
                    Company: $('#tbNvidiaGridCompany').val(),
                    Website: $('#tbNvidiaGridWebsite').val(),
                    Address: $('#tbNvidiaGridAddress').val(),
                    Country: $("#ddNvidiaGridCountry option:selected").val(),
                    CompanyDescription: $("#ddCompanyDescription option:selected").val(),
                    Quantity: $("#ddNvidiaGridQuantity option:selected").val(),
                    UseCase: $("#ddNvidiaGridUseCase option:selected").val(),
                    DesktopSolution: $("#ddNvidiaGridDesktopSolution option:selected").val(),
                    Message: $('#tbNvidiaGridNotes').val(),
                    Captcha: grecaptcha.getResponse()
                }),
                contentType: "application/json; charset=utf-8"
            }).done(function () {
                siteMessageSuccess("One of our HPC experts will contact your shortly to discuss your requirements.", 10000);
            }).fail(function (xhr) {
                grecaptcha.reset();
                if (xhr.responseJSON !== undefined && xhr.responseJSON !== null && xhr.responseJSON.Message !== null && xhr.responseJSON.Message !== undefined && xhr.responseJSON.Message.length > 0) {
                    siteMessageError("An issue has occured. " + xhr.responseJSON.Message, 10000);
                }
                else {
                    siteMessageError("An issue has occured.", 10000);
                }
            });
        });
    }
    catch (err) {
        console.log("no whitepaper registration");
        console.log(err);
    }
}

//Used by _register-partner-interest.cshtml
function SetupPartnerInterestRegistrationForm() {
    try {
        $('#btnPartnerInterestSubmit').on('click', function () {
            var partner = $(this).data('partner');
            if (partner === null || partner === undefined) {
                console.log("Critical error. No partner: " + partner);
                return;
            }
            $.ajax({
                cache: false,
                url: "/api/contact/partner-interest",
                type: "POST",
                data: JSON.stringify({
                    Name: $("#txtPartnerInterestName").val(),
                    Email: $("#txtPartnerInterestEmail").val(),
                    Company: $("#txtPartnerInterestCompany").val(),
                    Product: $("#txtPartnerInterestProduct").val(),
                    Misc: $("#txtPartnerInterestMoreFields").val(),
                    Partner: partner
                }),
                contentType: "application/json; charset=utf-8"
            }).done(function () {
                siteMessageSuccess("Your message was sent successfully.", 10000);
            }).fail(function (xhr) {
                if (xhr.responseJSON !== undefined && xhr.responseJSON !== null && xhr.responseJSON.Message !== null && xhr.responseJSON.Message !== undefined && xhr.responseJSON.Message.length > 0) {
                    siteMessageError("An issue has occured. " + xhr.responseJSON.Message, 10000);
                }
                else {
                    siteMessageError("An issue has occured.", 10000);
                }
            });
        });
    }
    catch (err) {
        console.log("no registration");
        console.log(err);
    }
}

//Used by _product-info-registration.cshtml
function SetupProductInformationRequestForm() {

    try {
        var _val = "GB";
        setTimeout(function () {
            $.ajax({
                cache: true,
                async: false,
                url: "/api/contact/get-countries",
                dataType: "json",
                type: "GET",
                contentType: "application/json; charset=utf-8",
                success: function (data) {

                    //Remove the loading message
                    $('#ddCountry').find("option").remove();
                    //Add all countries
                    $.each(data, function (index, item) {
                        $('#ddCountry').append($("<option />").text(item.Key).val(item.Value));
                    });

                    $('#ddCountry option[value="' + _val + '"]').attr("selected", true);
                }
            });
        }, 1);

        $('#ddContactHow').on('change', function () {
            if ($('#ddContactHow option:selected').val() === 'E') {
                $('#liEmail').show();
                $('#liCountry').hide();
                $('#liCall').hide();
            }
            else {
                $('#liEmail').hide();
                $('#liCountry').show();
                $('#liCall').show();
            }
        });
        $('#btnProductInfoRegistrationSubmit').click(function () {

            var styleAsInvalid = function (control) {
                control.css("border", "2px inset red").focus();
            };

            var isFormValid = true;
            $("#tbName:visible, #tbEmail:visible, #tbPhone:visible").each(function () {
                if ($(this).val() === "") {
                    isFormValid = false;
                    styleAsInvalid($(this));
                }
            });
            if (!isFormValid) return false;

            var dto = {
                name: $("#tbName").val(),
                company: $("#tbCompany").val(),
                email: $("#tbEmail").val(),
                country: $("#ddCountry").val(),
                phone: $("#tbPhone").val()
            };

            var reference = $("#tbReference").val("itemcode");
            if (reference === null || reference === undefined || reference.length < 1) {
                console.log("setting reference as page's title");
                reference = $(document).prop('title'); // Page's title
            }
            //Add the item code as we don't need it in the cookie
            dto["itemCode"] = reference;

            $(this).dialog("destroy");

            $.ajax({
                url: "/api/contact/product-information-registration",
                dataType: "json",
                type: "POST",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(dto),
                success: function () {
                    Dialog("We will be in touch shortly with more information", "Thank you for your request", true);
                },
                error: function (xhr) {
                    ExceptionDialog("Your request could not be submitted at this time\nPlease try again later", xhr);
                }
            });

        });
    }
    catch (err) {
        console.log("no years");
        console.log(err);
    }
}

//Used by _contactus.cshtml
function SetupContactUsForm() {
    try {

        var itemCode = getParameterByName("itemCode");

        if (itemCode !== null && itemCode !== undefined && itemCode.length > 0) {
            $('#tbMessage').val("Regarding item '" + itemCode + "'. When would this be available?");
        }

        $('#ddDepartment').on('change', function () {
            if ($("#ddDepartment option:selected").val() === "6") {
                $('#liSupport').show();
                $("#btnSendContactForm").prop('disabled', true);
                $("#tbName").prop('disabled', true);
                $("#tbEmail").prop('disabled', true);
                $("#tbPhone").prop('disabled', true);
                $('#tbMessage').prop('disabled', true);
            }
            else {
                $('#liSupport').hide();
                $("#tbName").prop('disabled', false);
                $("#btnSendContactForm").prop('disabled', false);
                $("#tbEmail").prop('disabled', false);
                $("#tbPhone").prop('disabled', false);
                $('#tbMessage').prop('disabled', false);
            }
        });
        $('#btnSendContactForm').on('click', function () {
            $.ajax({
                cache: false,
                url: "/api/contact",
                type: "POST",
                data: JSON.stringify({
                    Name: $("#tbName").val(),
                    Email: $("#tbEmail").val(),
                    Phone: $("#tbPhone").val(),
                    Message: $('#tbMessage').val(),
                    Department: $("#ddDepartment option:selected").val() * 1,
                    Captcha: grecaptcha.getResponse()
                }),
                contentType: "application/json; charset=utf-8"
            }).done(function () {
                siteMessageSuccess("Thank you for your message. A member of our team will be in touch soon.", 10000);
            }).fail(function (xhr) {
                $('#divError').show();
                grecaptcha.reset();
                if (xhr.responseJSON !== undefined && xhr.responseJSON !== null && xhr.responseJSON.Message !== null && xhr.responseJSON.Message !== undefined && xhr.responseJSON.Message.length > 0) {
                    siteMessageError("An issue has occured. " + xhr.responseJSON.Message, 10000);
                }
                else {
                    siteMessageError("An issue has occured.", 10000);
                }
            });
        });
    }
    catch (err) {
        console.log("no whitepaper registration");
        console.log(err);
    }
}

//Used by _marketing-document-by-year.cshtml
function SetupMarketingDocumentYearFilterMenu() {
    try {
        $('#yearFilter a').click(function () {
            var year = $(this).text();
            $('.documentList .row').hide();
            $('.documentList .' + year).closest('.row').show();
        });
        $('#yearFilter a.all').click(function () {
            $('.documentList .row').show();
        });
    }
    catch (err) {
        console.log("Unable to setup year filter for menu", err);
    }
}

//Used by WhatsNew/Index.cshtml
function Scroll(location, headerOffSet) {
    if (headerOffSet === undefined) headerOffSet = 80;

    $('html, body').animate({
        scrollTop: location.offset().top - headerOffSet
    }, 1000);
}

jQuery.fn.RotateNext = function () {
    return this.each(function () {
        var parentID = '#' + this.id;
        var current = $('ul li.show', this).length ? $('ul li.show', this) : $('ul li:first', this);
        var next = current.next().length ? current.next().hasClass('show') ? $('ul li:first', this) : current.next() : $('ul li:first', this);

        //there is a description to hide
        if ($('div.block', current).length > 0) {
            //hide the current description
            $('div.block', current).slideUp(800, function () {
                //fade out the current image
                current.removeClass('show')
                    .hide();
                //IE-PNG-Issue//.animate({ opacity: 0.0 }, 800);
                //fade in the next image
                //IE-PNG-Issue//next.css({ opacity: 0.0 })
                next.hide()
                    .addClass('show')
                    .show();
                //IE-PNG-Issue//.animate({ opacity: 1.0 }, 800, function() {
                if ($('div.block', next).length > 0) {
                    //show the next description
                    $('div.block', next)
                        .css({ opacity: 0.85 })
                        .slideDown(800);
                }
                //IE-PNG-Issue//})
                //set the image opacity to 100% so that the description appears semi-transparent
                next.find('img').show();
                //.css({ opacity: 1.0 });
            });
        }
        //there is no description to hide
        else {
            //fade out the current image
            current.removeClass('show')
                .hide();
            //IE-PNG-Issue//.animate({ opacity: 0.0 }, 800);
            //fade in the next image
            //IE-PNG-Issue//next.css({ opacity: 0.0 })
            next.hide()
                .addClass('show')
                .show();
            //IE-PNG-Issue//.animate({ opacity: 1.0 }, 800, function() {
            if ($('div.block', next).length > 0) {
                //show the next description
                $('div.block', next)
                    .css({ opacity: 0.85 })
                    .slideDown(800);
            }
            //IE-PNG-Issue//})
            //set the image opacity to 100% so that the description appears semi-transparent
            next.find('img').show();
            //.css({ opacity: 1.0 });
        }
    });
};

jQuery.fn.RotatePrevious = function () {
    return this.each(function () {
        var parentID = '#' + this.id;
        var current = $('ul li.show', this).length ? $('ul li.show', this) : $('ul li:first', this);
        var prev = current.prev().length ? current.prev().hasClass('show') ? $('ul li:last', this) : current.prev() : $('ul li:last', this);

        //there is a description to hide
        if ($('div.block', current).length > 0) {
            //hide the current description
            $('div.block', current).slideUp(800, function () {
                //fade out the current image
                current.removeClass('show')
                    .hide();
                //IE-PNG-Issue//.animate({ opacity: 0.0 }, 800);
                //fade in the previous image
                //IE-PNG-Issue//prev.css({ opacity: 0.0 })
                prev.hide()
                    .addClass('show')
                    .show();
                //IE-PNG-Issue//.animate({ opacity: 1.0 }, 800, function() {
                if ($('div.block', prev).length > 0) {
                    //show the previous description
                    $('div.block', prev)
                        .css({ opacity: 0.85 })
                        .slideDown(800);
                }
                //IE-PNG-Issue//})
                //set the image opacity to 100% so that the description appears semi-transparent
                prev.find('img').show();
                //.css({ opacity: 1.0 });
            });
        }
        //there is no description to hide
        else {
            //fade out the current image
            current.removeClass('show')
                .hide();
            //IE-PNG-Issue//.animate({ opacity: 0.0 }, 800);
            //fade in the previous image
            //IE-PNG-Issue//prev.css({ opacity: 0.0 })
            prev.hide()
                .addClass('show')
                .show();
            //IE-PNG-Issue//.animate({ opacity: 1.0 }, 800, function() {
            if ($('div.block', prev).length > 0) {
                //show the previous description
                $('div.block', prev)
                    .css({ opacity: 0.85 })
                    .slideDown(800);
            }
            //IE-PNG-Issue//})
            //set the image opacity to 100% so that the description appears semi-transparent
            prev.find('img').show();
            //.css({ opacity: 1.0 });
        }
    });
};

jQuery.fn.Rotate = function (options) {
    options = jQuery.extend({
        delay: 6000,
        buttons: false
    }, options);

    return this.each(function () {
        var parent = $(this);
        var parentID = '#' + this.id;
        $('ul li', parent).hide(); //.css({ opacity: 0.0 });
        var current = $('ul li.show', parent).length ? $('ul li.show', parent) : $('ul li:first', parent);

        //show the current image
        //IE-PNG-Issue//$(current).css("opacity", "1.0").find('img').css({ opacity: 1.0 });
        $(current).show().find('img').show();

        //show the description
        if ($('div.block', current).length > 0) {
            $('div.block', current)
                .css({ opacity: 0.85 })
                .slideDown(800);
        }

        //build the command to change the slides
        var rotateCommand = "$('" + parentID + "').RotateNext();";

        if (options.buttons === true) {
            $('ul li', parent).each(function () {
                $(this).append('<div class="left" title="Previous slide"></div>');
                $(this).append('<div class="right" title="Next slide"></div>');
            });
            $('ul li div.left', parent).click(function (event) {
                $(parentID).RotatePrevious();
            });
            $('ul li div.right', parent).click(function (event) {
                $(parentID).RotateNext();
            });
        }

        //register the timer
        setInterval(rotateCommand, options.delay);
    });
};

jQuery.fn.Scroll = function () {
    return this.each(function () {
        var control = $(this);

        //remove js-disabled class
        $("#viewer", control).removeClass("js-disabled");

        var imageWidth = 0;
        $(".wrapper", control).each(function () {
            imageWidth = imageWidth + $(this).width();
        });

        //create new container for images
        $("<div>").attr("id", "imageScrollerContainer")
            .css({ position: "absolute" })
            .width(imageWidth)
            .appendTo("div#viewer");

        //add images to container
        $(".wrapper", control).each(function () {
            $(this).appendTo("div#imageScrollerContainer");
        });

        //work out duration of anim based on number of images (1 second for each image)
        var duration = $(".wrapper", control).length * 4000;

        //store speed for later (distance / time)
        var speed = (parseInt($("div#imageScrollerContainer", control).width()) + parseInt($("div#viewer", control).width())) / duration;

        //set initial position and class based on direction
        $("div#imageScrollerContainer", control).css("left", $("div#viewer", control).width());

        //animator function
        var animator = function (el, time) {
            //animate the el
            el.animate({ left: "-" + el.width() + "px" }, time, "linear", function () {
                //reset container position
                //$(this).css({ left: $("div#imageScroller").parent().parent().width(), right: "" });
                $(this).css({ left: $("div#imageScroller").width(), right: "" });

                //restart animation
                animator($(this), duration);
            });
        };

        //start anim
        animator($("div#imageScrollerContainer"), duration);

        //pause on mouseover
        $("a.wrapper", control).on("mouseover", function () {
            //stop anim
            $(this).parent().stop(true);
        });

        //restart on mouseout
        $("a.wrapper", control).on("mouseout", function (e) {
            var container = $(this).parent();

            //work out total travel distance
            var totalDistance = parseInt(container.width()) + parseInt($("div#viewer").width());

            //work out distance left to travel
            var distanceLeft = totalDistance - parseInt($("div#viewer").width() - parseInt(container.css("left")));

            //new duration is distance left / speed)
            var newDuration = distanceLeft / speed;

            //restart anim
            animator(container, newDuration);
        });
    });
};

$.fn.insertAtCaret = function (myValue) {
    return this.each(function () {
        //IE support
        if (document.selection) {
            this.focus();
            sel = document.selection.createRange();
            sel.text = myValue;
            this.focus();
        }
        //MOZILLA/NETSCAPE support
        else if (this.selectionStart || this.selectionStart === '0') {
            var startPos = this.selectionStart;
            var endPos = this.selectionEnd;
            var scrollTop = this.scrollTop;
            this.value = this.value.substring(0, startPos)
                + myValue
                + this.value.substring(endPos, this.value.length);
            this.focus();
            this.selectionStart = startPos + myValue.length;
            this.selectionEnd = startPos + myValue.length;
            this.scrollTop = scrollTop;
        } else {
            this.value += myValue;
            this.focus();
        }
    });
};

$.maxZIndex = $.fn.maxZIndex = function (opt) {
    /// <summary>
    /// Returns the max zOrder in the document (no parameter)
    /// Sets max zOrder by passing a non-zero number
    /// which gets added to the highest zOrder.
    /// </summary>
    /// <param name="opt" type="object">
    /// inc: increment value,
    /// group: selector for zIndex elements to find max for
    /// </param>
    /// <returns type="jQuery" />
    var def = { inc: 10, group: "*" };
    $.extend(def, opt);
    var zmax = 0;
    $(def.group).each(function () {
        var cur = parseInt($(this).css('z-index'));
        zmax = cur > zmax ? cur : zmax;
    });
    if (!this.jquery)
        return zmax;

    return this.each(function () {
        zmax += def.inc;
        $(this).css("z-index", zmax);
    });
};

$.fn.multiswipe = function (options) {
    if (window.navigator.userAgent.indexOf('iPhone') === -1 && window.navigator.userAgent.indexOf('iPad') === -1)
        return;

    // Default thresholds & swipe functions
    var defaults = {
        threshold: 50,
        fingers: 2,
        swipeLeft: function () { },
        swipeRight: function () { },
        swipeUp: function () { },
        swipeDown: function () { }
    };

    options = $.extend(defaults, options);

    if (!this) return false;

    return this.each(function () {
        var me = $(this);
        var gestureActive = false;

        // Private variables for each element
        var originalCoord = { x: 0, y: 0 };
        var finalCoord = { x: 0, y: 0 };

        // Screen touched, store the original coordinate
        function gestureStart(event) {
            console.log('Starting swipe gesture...');
            gestureActive = true;
        }

        function touchStart(event) {
            //event.preventDefault();
            if (gestureActive && event.targetTouches.length === defaults.fingers) {
                originalCoord.x = event.targetTouches[defaults.fingers - 1].screenX;
                originalCoord.y = event.targetTouches[defaults.fingers - 1].screenY;
            }
        }

        // Store coordinates as finger is swiping
        function touchMove(event) {
            //event.preventDefault();
            if (gestureActive && event.targetTouches.length === defaults.fingers) {
                finalCoord.x = event.targetTouches[defaults.fingers - 1].screenX; // Updated X,Y coordinates
                finalCoord.y = event.targetTouches[defaults.fingers - 1].screenY;
            }
        }

        // Done Swiping
        // Trigger all relevant multiswipe events
        function gestureEnd(event) {
            console.log('Ending swipe gesture...');
            var changeY = originalCoord.y - finalCoord.y;
            var changeX = originalCoord.x - finalCoord.x;

            if (changeY > defaults.threshold) {
                defaults.swipeUp();
            }
            if (changeY < defaults.threshold * -1) {
                defaults.swipeDown();
            }
            if (changeX > defaults.threshold) {
                defaults.swipeLeft();
            }
            if (changeX < defaults.threshold * -1) {
                defaults.swipeRight();
            }
            gestureActive = false;
        }

        // Add gestures to all swipable areas
        this.addEventListener("gesturestart", gestureStart, false);
        this.addEventListener("touchstart", touchStart, false);
        this.addEventListener("touchmove", touchMove, false);
        this.addEventListener("gestureend", gestureEnd, false);
    });
};

function Dialog(message, title, closeOnEscape, width) {
    if ($("#siteDialog").dialog().dialog("isOpen") === true)
        $("#siteDialog").dialog("destroy");

    if (title === "" | title === undefined)
        title = document.title;

    if (width === "" | width === undefined)
        width = "400";

    message = message.replace(/\n/g, "<br />");

    $("#siteDialog").html(message)
        .dialog({
            'title': title,
            'closeOnEscape': closeOnEscape,
            draggable: false,
            modal: true,
            resizable: false,
            'width': width,
            buttons: { "Close": function () { $(this).dialog("close"); } }
        });
}

function DialogWithDetail(message, detail, title, closeOnEscape, onClose) {
    if ($("#siteDialog").dialog().dialog("isOpen") === true)
        $("#siteDialog").dialog("destroy");

    if (title === "" | title === undefined)
        title = document.title;

    if (detail === "" | detail === undefined)
        detail = "No detail provided";

    message = message.replace(/\n/g, "<br />");
    detail = detail.replace(/\n/g, "<br />");

    var para1 = $("<p />").attr("id", "dlgPmessage").html(message);
    var para2 = $("<p />").attr("id", "dlgPdetail").html(detail).css("display", "none");

    para1.dblclick(function () { $("#dlgPmessage").hide(); $("#dlgPdetail").show(); });
    para2.dblclick(function () { $("#dlgPdetail").hide(); $("#dlgPmessage").show(); });

    $("#siteDialog").html("").append(para1).append(para2)
        .dialog({
            'title': title,
            'closeOnEscape': closeOnEscape,
            draggable: false,
            modal: true,
            resizable: false,
            buttons: {
                "Close": function () {
                    $(this).dialog("close");
                    if (onClose !== undefined) {
                        onClose();
                    }
                }
            }
        });
}

function ExceptionDialog(message, xhr, onClose) {
    var detail = "No detail provided";
    if (xhr !== undefined) {
        var jsonFault = JSON.parse(xhr.responseText);
        detail = jsonFault.FaultType + ": " + jsonFault.Message;
    }
    DialogWithDetail(message, detail, "", true, onClose);
}

function Confirm(message, title, onAccept, onReject) {
    if ($("#siteDialog").dialog().dialog("isOpen") === true)
        $("#siteDialog").dialog("destroy");

    if (title === "" | title === undefined)
        title = document.title;

    message = message.replace(/\n/g, "<br />");

    $("#siteDialog").html(message)
        .dialog({
            'title': title,
            closeOnEscape: false,
            draggable: false,
            modal: true,
            resizable: false,
            width: '400',
            buttons: {
                "Yes": function () {
                    $(this).dialog("destroy");
                    if (onAccept !== null & onAccept !== undefined)
                        onAccept();
                },
                "No": function () {
                    $(this).dialog("destroy");
                    if (onReject !== null & onReject !== undefined)
                        onReject();
                }
            },
            open: function () {
                $(".ui-dialog-titlebar-close").hide();
                $(this).parents('.ui-dialog-buttonpane button:eq(1)').focus();
            }
        });
}

function HideDialog() {
    if ($("#siteDialog").dialog().dialog("isOpen") === true)
        $("#siteDialog").dialog("destroy");
}

//Site messages
function siteMessageSuccess(message, delay) {
    siteMessage(message, 'alert-success', delay);
}
function siteMessageError(message, delay, detail) {
    siteMessage(message, 'alert-danger', delay, detail);
}
function siteMessage(message, style, delay, detail) {
    if (style === undefined) {
        style = 'alert-info';
    }
    if (delay === undefined) {
        delay = 5000;
    }
    if (detail === undefined) {
        detail = '';
    }
    if ($("div#siteMessage").length >= 1) {
        $("div#siteMessage")
            .stop(true, true)
            .remove();
    }

    $("<div />").prop('id', 'siteMessage').css({ 'position': 'fixed', 'top': '0', 'left': '0', 'width': '100%', "z-index": "9999" }).hide()
        .append($("<div />").addClass("alert " + style + " alert-dismissible").prop("role", "alert").html(message)
            .append($("<span />", { 'class': 'close' }).data("dismiss", "alert").html("&times;").click(function () {
                $("#siteMessage")
                    .stop(true, true).slideUp(800, function () {
                        $(this).remove();
                    });
            }))
            .append($("<div />").html(detail))
        )
        .hide()
        .prependTo("body")
        .slideDown(800)
        .delay(delay)
        .slideUp(800, function () { $(this).remove(); });
}

function getGrenkeResults(capitalValue, paymentCycle, terms, onSuccess, onFailure) {
    $.ajax({
        cache: false,
        type: "GET",
        url: '/api/leasing/calculate/' + capitalValue + '/' + paymentCycle + '/' + terms,
    }).done(onSuccess).fail(onFailure);
}

function commafyValue(nStr) {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.href);
    if (results === null) {
        return "";
    } else {
        return decodeURIComponent(results[1].replace(/\+/g, " "));
    }
}

var trackEvent = function (eventName) {
    if (window.appInsights === undefined) {
        return true;
    }

    if (window.appInsights === null) {
        return true;
    }

    window.appInsights.trackEvent(eventName);
    window.appInsights.flush();
    return true;
};
