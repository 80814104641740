var eCommerce = new function () {

	this.AddToCart = function (itemCode) {
		$.post("/api/cart/" + itemCode)
		.done(function () {
			$("#addToCartSuccessModal").modal("show");
		})
		.fail(function () {
			$("#addToCartErrorModal").modal("show");
		});
	};

	return this;
}();
