$(function () {

	//Request product info
	$("#requestProductInfoModal #rpiSubmit").click(function () {

		if ($("#rpiForm").valid() === false) {
			return;
		}

		//disable buttons
		$("#requestProductInfoModal .modal-footer .btn").prop("disabled", true);

		var data = {
			itemCode: $("#requestProductInfoModal #rpiItemCode").val(),
			name: $("#requestProductInfoModal #rpiName").val(),
            email: $("#requestProductInfoModal #rpiEmail").val(),
            Captcha: grecaptcha.getResponse()
		};

		$.post("/api/contact/request-product-info", data)
			.done(function () {
				//hide this dialog
				$("#requestProductInfoModal").modal("hide");
				//show thanks message
				$("#requestProductInfoThanksModal").modal("show");
			})
			.fail(function () {
				console.log("error");
				//hide this dialog
				$("#requestProductInfoModal").modal("hide");
				//show an error
				$("#requestProductInfoErrorModal").modal("show");

			})
			.always(function () {
				//enable buttons
				$("#requestProductInfoModal .modal-footer .btn").prop("disabled", false);
			});

	});

	//Send to a friend
	$("#sendToAFriendModal #stafSubmit").click(function () {

		var modal = $("#sendToAFriendModal");
		var thanksModal = $("#sendToAFriendThanksModal");
		var errorModal = $("#sendToAFriendErrorModal");

		if ($("#stafForm", modal).valid() === false) {
			return;
		}

		//disable buttons
		$(".modal-footer .btn", modal).prop("disabled", true);

		var data = {
			url: window.location.toString(),
			name: $("#stafName", modal).val(),
			email: $("#stafEmail", modal).val(),
			friendsName: $("#stafFriendsName", modal).val(),
			friendsEmail: $("#stafFriendsEmail", modal).val(),
            message: $("#stafMessage", modal).val(),
            Captcha: grecaptcha.getResponse()
		};

		$.post("/api/contact/send-to-friend", data)
			.done(function () {
				//hide this dialog
				$(modal).modal("hide");
				//show thanks message
				thanksModal.modal("show");
			})
			.fail(function () {
				console.log("error");
				//hide this dialog
				$(modal).modal("hide");
				//show an error
				errorModal.modal("show");

			})
			.always(function () {
				//enable buttons
				$(".modal-footer .btn", modal).prop("disabled", false);
			});

	});

});
