function DirectSearch(SiteSearchSearchTermControl, SiteSearchResultsListPlaceHolder) {
    $.ajax({
        type: "POST",
        cache: "true",
        contentType: "application/json; charset=utf-8",
        url: "/api/items/site-search",
        data: JSON.stringify({ Term: $(SiteSearchSearchTermControl).val(), ResultCount: 10 }),
        dataType: "json",
        success: function (response) {
            var results = typeof response === 'string' ? eval('(' + response + ')') : response;

            if (results) {
                console.log("results.length", results.length);
                    var body = $('<tbody />');

                    body.append($('<tr />')
                        .append($('<td />').prop('colspan', '2').addClass('text-left autocomplete-suggestions-header').html("Suggested Products<small>(Press enter or click the button to search all pages)</small>")));
                    for (var i = 0; i < results.length; i++) {
                        body.append($('<tr />')
                            .append($('<td />').addClass("image").append($('<div />').addClass('subtleborder').html("<a href='" + results[i].ProductURL + "'><img src='" + results[i].ImageURL + "' /></a>")))
                            .append($('<td />').addClass('info').html("<a href='" + results[i].ProductURL + "'>" + results[i].ItemName + "</a>")));
                    }

                    if (results.length === 0) {
                        body.append($('<tr />')
                            .append($('<td />').prop('colspan', '2').addClass('text-left autocomplete-suggestions-header').html("<strong>No matches found<strong>")));
                    }
                    $(SiteSearchResultsListPlaceHolder)
                        .empty()
                        .append($('<table />').append(body))
                        .show();
                    var pos = $(SiteSearchSearchTermControl).offset();
                    var height = $(SiteSearchSearchTermControl).height();
                
                    $(SiteSearchResultsListPlaceHolder).fadeIn();
            }
            else {
                $(SiteSearchResultsListPlaceHolder).hide();
            }
        }
    });
}
